import React, {useEffect, useState} from 'react';
import {Content, Parallax} from "../../App";
import styled from "styled-components";
import {Grid} from "../home";
import Footer from "../../components/Footer";
import CallToAction from "./components/CallToAction";
import MenuSection from "./components/new/MenuSection";
import DeliverySection from "./components/new/DeliverySection";
import QuantitySection from "./components/new/QuantitySection";
import DateSection from "./components/new/DateSection";
import classNames from "classnames";

export const FormulaComparator = styled.div<{ items: number }>`
  display: grid;
  grid-gap: 8px;

  // @media only screen and (min-width: 600px) {
  //   grid-template-columns:  1fr 1fr;
  //
  // }
  //
  // @media only screen and (min-width: 1150px) {
    //   grid-template-columns: ${props => `repeat(${props.items > 3 ? 2 : props.items}, 1fr)`};
  // }
  grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));

  &.p-invalid {
    border: 1px solid var(--invalid);
  }
`;

export const FormulaGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
`;
const Pricing = styled.div`
  grid-template-columns: max-content max-content max-content 1fr;
  display: grid;
  grid-gap: 8px 16px;
`;

export const Questions = styled.div`
  padding: 32px;
  background-color: var(--green-40);
  text-align: center;


  @media only screen and (min-width: 600px) {
    padding: 8px 32px;
  }

  h3, p {
    margin: 0;
  }
`;

const SubNavWrapper = styled.div`
  top: 96px;
  @media only screen and (max-width: 960px) {
    top: 60px;
  }
`;

const BasketsPageNew = () => {

  const [activeSection, setActiveSection] = useState("");

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll(".section");
      let currentSection: string | null = "";

      sections.forEach((section) => {
        const sectionTop = (section as HTMLElement).offsetTop;
        const sectionHeight = (section as HTMLElement).offsetHeight;
        if (window.scrollY >= sectionTop - sectionHeight / 3) {
          currentSection = section.getAttribute("id");
        }
      });

      setActiveSection(currentSection);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return <div>
    <SubNavWrapper className="z-5 bg-white-alpha-90 fixed left-0 right-0 border-bottom-1 border-gray-400">
      <Content className='pb-0'>
        <div className="flex justify-content-between align-items-center">
          <div
            className={classNames("font-medium text-lg pb-3", {"border-bottom-2 pb-1 h-full color-orange font-bold border-orange-600": activeSection === "wat"})}>
            <a href="#wat" className={classNames({"color-orange": activeSection === "wat"})}>Wat</a></div>
          <div
            className={classNames("font-medium text-lg pb-3", {"border-bottom-2 pb-1 h-full color-orange font-bold border-orange-600": activeSection === "hoeveel"})}>
            <a href="#hoeveel" className={classNames({"color-orange": activeSection === "hoeveel"})}>Hoeveel</a></div>
          <div
            className={classNames("font-medium text-lg pb-3", {"border-bottom-2 pb-1 h-full color-orange font-bold border-orange-600": activeSection === "wanneer"})}>
            <a href="#wanneer" className={classNames({"color-orange": activeSection === "wanneer"})}>Wanneer</a></div>
          <div
            className={classNames("font-medium text-lg pb-3", {"border-bottom-2 pb-1 h-full color-orange font-bold border-orange-600": activeSection === "waar"})}>
            <a href="#waar" className={classNames({"color-orange": activeSection === "waar"})}>Waar</a></div>
          <div className={classNames("color-orange border-1 border-round font-medium text-lg mb-3 p-1 ")}>
            <a href="https://www.mijnboer.be/groentegeweld/pakketten"
               className={"color-orange font-bod"}>Inschrijven</a>
          </div>
        </div>
      </Content>
    </SubNavWrapper>

    <div className='section' id="wat">
      <MenuSection/>
    </div>
    <CallToAction type={'pakketten'} label={'Schrijf mij in voor een flex-pakket'}/>
    <div className='section' id="hoeveel">
      <QuantitySection/>
    </div>
    <div className='section' id="wanneer">
      <DateSection/>
    </div>
    <CallToAction type={'pakketten'} label={'Schrijf mij in voor een flex-pakket'}/>
    <div className='section' id="waar">
      <DeliverySection/>
    </div>


    <Content>
      <div className="my-8">
        <FormulaGrid className="">
          <div className="border-1 border-gray-100 shadow-1 m-2 p-2 flex-grow-1 p-1 flex flex-column">
            <div className="p-0">
              <div className="p-0">
                <div className='h-full  grid grid-nogutter justify-content-center align-items-center'>
                  <h1 className='pb-0 mb-0 text-lg sm:text-2xl lg:text-3xl'>Flex <span
                    className="highlight">40</span></h1>
                </div>
              </div>
            </div>
            <div className="p-0">
              <div
                className='h-full grid grid-nogutter justify-content-center align-items-center font-bold text-lg sm:text-xl md:2xl color-orange'>40 <span
                className="text-xs sm:text-sm md:text-md ld:text-lg pl-1 text-gray-400 font-normal"> pakketten</span>
              </div>
            </div>
            <div className="p-1 pb-0">
              <div className='flex flex-column justify-content-center align-items-center'>
                <div className="text-md md:text-xl lg:text-2xl font-medium color-orange line-height-2">Wekelijks</div>
                <div className="text-xs sm:text-sm md:text-md color-green line-height-1 pb-2">(februari - maart
                  tweewekelijks)
                </div>
                <div className="text-sm text-gray-500 line-height-1 font-italic">januari: geen pakketten</div>
              </div>
            </div>
            <div className="p-0">
              <div className="h-full grid grid-nogutter pt-4 pb-2 text-lg font-medium color-green align-items-end">
                <div className='col-4 text-center text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl'>Mini</div>
                <div className='col-4 text-center text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl'>Klein</div>
                <div className='col-4 text-center text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl'>Groot</div>
              </div>
            </div>
            <div className="p-0">
              <div className='h-full grid grid-nogutter align-items-center'>
                <div className='col-4 white-space-nowrap text-center color-orange text-sm font-bold'><span
                  className="text-gray-400 font-normal text-xs sm:text-sm md:text-md">&euro;</span> 11
                  <span className="text-xs font-medium"> / pakket</span></div>
                <div className='col-4 white-space-nowrap text-center color-orange text-sm font-bold'><span
                  className="text-gray-400 font-normal text-xs sm:text-sm md:text-md">&euro;</span> 18
                  <span className="text-xs font-medium"> / pakket</span></div>
                <div className='col-4 white-space-nowrap text-center color-orange text-sm font-bold'><span
                  className="text-gray-400 font-normal text-xs sm:text-sm md:text-md">&euro;</span> 26
                  <span className="text-xs font-medium"> / pakket</span></div>
              </div>
            </div>
            <div className="p-0">
              <div className='h-full grid grid-nogutter align-items-center'>
                <div className="col-4">
                  <div className='text-center color-green text-md font-medium'><span
                    className="text-gray-400 font-normal">&euro;</span> 435
                  </div>
                </div>
                <div className="col-4">
                  <div className='text-center color-green text-base font-medium'><span
                    className="text-gray-400 font-normal">&euro;</span> 720
                  </div>
                </div>
                <div className="col-4">
                  <div className='text-center color-green text-lg font-medium'><span
                    className="text-gray-400 font-normal">&euro;</span> 1040
                  </div>
                </div>
              </div>
            </div>
            <div className="p-0">
              <div className='h-full grid grid-nogutter align-items-center'>
                <div className="col-4">
                  <div className='pt-4 text-center text-sm'><a
                    className="border-2 border-orange border-round color-orange p-1"
                    href="https://www.mijnboer.be/groentegeweld/inschrijven/pakketten?code=groentegeweld|pakketten-2023-flex-40-mini"
                    target="_blank">Deze wil ik</a></div>
                </div>
                <div className="col-4">
                    <div className='pt-4 text-center text-sm'><a
                    className="border-2 border-orange border-round color-orange p-1"
                    href="https://www.mijnboer.be/groentegeweld/inschrijven/pakketten?code=pakketten-2023-flex-40-medium"
                    target="_blank">Deze wil ik</a></div>
                </div>
                <div className="col-4">
                    <div className='pt-4 text-center text-sm'><a
                    className="border-2 border-orange border-round color-orange p-1"
                    href="https://www.mijnboer.be/groentegeweld/inschrijven/pakketten?code=pakketten-2023-flex-40-large"
                    target="_blank">Deze wil ik</a></div>
                </div>
              </div>
            </div>
          </div>

          <div className="border-1 border-gray-100 shadow-1 m-2 p-2 flex-grow-1 p-1 flex flex-column">
            <div className="p-0">
              <div className='h-full  grid grid-nogutter justify-content-center align-items-center '>
                <h1 className='pb-0 mb-0 text-lg sm:text-2xl lg:text-3xl'>Flex <span
                  className="highlight">30</span></h1>
              </div>
            </div>
            <div className="p-0">
              <div
                className='h-full grid grid-nogutter justify-content-center align-items-center font-bold text-lg sm:text-xl md:2xl color-orange'>30 <span
                className="text-xs sm:text-sm md:text-md ld:text-lg pl-1 text-gray-400 font-normal"> pakketten</span>
              </div>
            </div>
            <div className="p-1 pb-0 flex-grow-1">
              <div className='flex flex-column justify-content-center align-items-center'>
                <div className="text-md md:text-xl lg:text-2xl font-medium color-orange line-height-2">Wekelijks</div>
                <div className="text-xs sm:text-sm md:text-md color-green line-height-1 pb-2">(februari - maart
                  tweewekelijks)
                </div>
                <div className="text-sm text-gray-500 line-height-1 font-italic">januari: geen pakketten</div>
              </div>
            </div>
            <div className="p-0">
              <div className="h-full grid grid-nogutter pt-4 pb-2 text-lg font-medium color-green align-items-end">
                <div className='col-4 text-center text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl'>Mini</div>
                <div className='col-4 text-center text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl'>Klein</div>
                <div className='col-4 text-center text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl'>Groot</div>
              </div>
            </div>
            <div className="p-0">
              <div className='h-full grid grid-nogutter align-items-center'>
                <div className='col-4 white-space-nowrap text-center color-orange text-sm font-bold'><span
                  className="text-gray-400 font-normal text-xs sm:text-sm md:text-md">&euro;</span> 11,5
                  <span className="text-xs font-medium"> / pakket</span></div>
                <div className='col-4 white-space-nowrap text-center color-orange text-sm font-bold'><span
                  className="text-gray-400 font-normal text-xs sm:text-sm md:text-md">&euro;</span> 19
                  <span className="text-xs font-medium"> / pakket</span></div>
                <div className='col-4 white-space-nowrap text-center color-orange text-sm font-bold'><span
                  className="text-gray-400 font-normal text-xs sm:text-sm md:text-md">&euro;</span> 27,5
                  <span className="text-xs font-medium"> / pakket</span></div>
              </div>
            </div>
            <div className="p-0">
              <div className='h-full grid grid-nogutter align-items-center'>
                <div className="col-4">
                  <div className='text-center color-green text-md font-medium'><span
                    className="text-gray-400 font-normal">&euro;</span> 340
                  </div>
                </div>
                <div className="col-4">
                  <div className='text-center color-green text-base font-medium'><span
                    className="text-gray-400 font-normal">&euro;</span> 570
                  </div>
                </div>
                <div className="col-4">
                  <div className='text-center color-green text-lg font-medium'><span
                    className="text-gray-400 font-normal">&euro;</span> 820
                  </div>
                </div>
              </div>
            </div>
            <div className="p-0">
              <div className='h-full grid grid-nogutter align-items-center'>
                <div className="col-4">
                  <div className='pt-4 text-center text-sm'><a
                    className="border-2 border-orange border-round color-orange p-1"
                    href="https://www.mijnboer.be/groentegeweld/inschrijven/pakketten?code=groentegeweld|pakketten-2023-flex-30-mini"
                    target="_blank">Deze wil ik</a></div>
                </div>
                <div className="col-4">
                  <div className='pt-4 text-center text-sm'><a
                    className="border-2 border-orange border-round color-orange p-1"
                    href="https://www.mijnboer.be/groentegeweld/inschrijven/pakketten?code=pakketten-2023-flex-30-medium"
                    target="_blank">Deze wil ik</a></div>
                </div>
                <div className="col-4">
                  <div className='pt-4 text-center text-sm'><a
                    className="border-2 border-orange border-round color-orange p-1"
                    href="https://www.mijnboer.be/groentegeweld/inschrijven/pakketten?code=pakketten-2023-flex-30-large"
                    target="_blank">Deze wil ik</a></div>
                </div>
              </div>
            </div>
          </div>
          <div className="border-1 border-gray-100 shadow-1 m-2 p-2 flex-grow-1 p-1 flex flex-column">
            <div className="p-0">
              <div className='h-full  grid grid-nogutter justify-content-center align-items-center'>
                <h1 className='pb-0 mb-0 text-lg sm:text-2xl lg:text-3xl'>Flex <span
                  className="highlight">15</span></h1>
              </div>
            </div>
            <div className="p-0">
              <div
                className='h-full grid grid-nogutter justify-content-center align-items-center font-bold text-lg sm:text-xl md:2xl color-orange'>15 <span
                className="text-xs sm:text-sm md:text-md ld:text-lg pl-1 text-gray-400 font-normal"> pakketten</span>
              </div>
            </div>
            <div className="p-1 pb-0 flex-grow-1">
              <div className='h-full p-1 flex flex-column justify-content-center align-items-center'>
                <div className="text-md md:text-xl lg:text-2xl font-medium color-orange line-height-2">Tweewekelijks
                </div>
                <div className="text-xs sm:text-sm md:text-md color-green line-height-1 pb-2">Heel het jaar</div>
                <div className="text-sm text-gray-500 line-height-1 font-italic">januari: geen pakketten</div>
              </div>
            </div>
            <div className="p-0">
              <div className="h-full grid grid-nogutter pt-4 pb-2 text-lg font-medium color-green align-items-end">
                <div className='col-4 text-center text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl'>Mini</div>
                <div className='col-4 text-center text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl'>Klein</div>
                <div className='col-4 text-center text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl'>Groot</div>
              </div>
            </div>
            <div className="p-0">
              <div className='h-full grid grid-nogutter align-items-center'>
                <div className='col-4 white-space-nowrap text-center color-orange text-sm font-bold'><span
                  className="text-gray-400 font-normal text-xs sm:text-sm md:text-md">&euro;</span> 12
                  <span className="text-xs font-medium"> / pakket</span></div>
                <div className='col-4 white-space-nowrap text-center color-orange text-sm font-bold'><span
                  className="text-gray-400 font-normal text-xs sm:text-sm md:text-md">&euro;</span> 19,5
                  <span className="text-xs font-medium"> / pakket</span></div>
                <div className='col-4 white-space-nowrap text-center color-orange text-sm font-bold'><span
                  className="text-gray-400 font-normal text-xs sm:text-sm md:text-md">&euro;</span> 28
                  <span className="text-xs font-medium"> / pakket</span></div>
              </div>
            </div>
            <div className="p-0">
              <div className='h-full grid grid-nogutter align-items-center'>
                <div className="col-4">
                  <div className='text-center color-green text-md font-medium'><span
                    className="text-gray-400 font-normal">&euro;</span> 180
                  </div>
                </div>
                <div className="col-4">
                  <div className='text-center color-green text-base font-medium'><span
                    className="text-gray-400 font-normal">&euro;</span> 295
                  </div>
                </div>
                <div className="col-4">
                  <div className='text-center color-green text-lg font-medium'><span
                    className="text-gray-400 font-normal">&euro;</span> 420
                  </div>
                </div>
              </div>
            </div>
            <div className="p-0">
              <div className='h-full grid grid-nogutter align-items-center'>
                <div className="col-4">
                  <div className='pt-4 text-center text-sm'><a
                    className="border-2 border-orange border-round color-orange p-1"
                    href="https://www.mijnboer.be/groentegeweld/inschrijven/pakketten?code=groentegeweld|pakketten-2023-flex-15-mini"
                    target="_blank">Deze wil ik</a></div>
                </div>
                <div className="col-4">
                  <div className='pt-4 text-center text-sm'><a
                    className="border-2 border-orange border-round color-orange p-1"
                    href="https://www.mijnboer.be/groentegeweld/inschrijven/pakketten?code=pakketten-2023-flex-15-medium"
                    target="_blank">Deze wil ik</a></div>
                </div>
                <div className="col-4">
                  <div className='pt-4 text-center text-sm'><a
                    className="border-2 border-orange border-round color-orange p-1"
                    href="https://www.mijnboer.be/groentegeweld/inschrijven/pakketten?code=pakketten-2023-flex-15-large"
                    target="_blank">Deze wil ik</a></div>
                </div>
              </div>
            </div>
          </div>
          <div className="border-1 border-gray-100 shadow-1 m-2 p-2 flex-grow-1 p-1 flex flex-column">
            <div className="p-0">
              <div className='h-full  grid grid-nogutter justify-content-center align-items-center'>
                <h1 className='pb-0 mb-0 text-lg sm:text-2xl lg:text-3xl'>Proef <span
                  className="highlight">8</span></h1>
              </div>
            </div>
            <div className="p-0">
              <div
                className='h-full grid grid-nogutter justify-content-center align-items-center font-bold text-lg sm:text-xl md:2xl color-orange'>8 <span
                className="text-xs sm:text-sm md:text-md ld:text-lg pl-1 text-gray-400 font-normal"> pakketten</span>
              </div>
            </div>
            <div className="p-1 pb-0 flex-grow-1">
              <div className='h-full p-1 flex flex-column justify-content-center align-items-center'>
                <div className="text-md md:text-xl lg:text-2xl font-medium color-orange line-height-2">Proefperiode
                </div>
              </div>
            </div>
            <div className="p-0">
              <div className="h-full grid grid-nogutter pt-4 pb-2 text-lg font-medium color-green align-items-end">
                <div className='col-4 text-center text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl'>Mini</div>
                <div className='col-4 text-center text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl'>Klein</div>
                <div className='col-4 text-center text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl'>Groot</div>
              </div>
            </div>
            <div className="p-0">
              <div className='h-full grid grid-nogutter align-items-center'>
                <div className='col-4 white-space-nowrap text-center color-orange text-sm font-bold'><span
                  className="text-gray-400 font-normal text-xs sm:text-sm md:text-md">&euro;</span> 11,5
                  <span className="text-xs font-medium"> / pakket</span></div>
                <div className='col-4 white-space-nowrap text-center color-orange text-sm font-bold'><span
                  className="text-gray-400 font-normal text-xs sm:text-sm md:text-md">&euro;</span> 19,5
                  <span className="text-xs font-medium"> / pakket</span></div>
                <div className='col-4 white-space-nowrap text-center color-orange text-sm font-bold'><span
                  className="text-gray-400 font-normal text-xs sm:text-sm md:text-md">&euro;</span> 27,5

                  <span className="text-xs font-medium"> / pakket</span></div>
              </div>
            </div>
            <div className="p-0">
              <div className='h-full grid grid-nogutter align-items-center'>
                <div className="col-4">
                  <div className='text-center color-green text-md font-medium'><span
                    className="text-gray-400 font-normal">&euro;</span> 92
                  </div>
                </div>
                <div className="col-4">
                  <div className='text-center color-green text-base font-medium'><span
                    className="text-gray-400 font-normal">&euro;</span> 152
                  </div>
                </div>
                <div className="col-4">
                  <div className='text-center color-green text-lg font-medium'><span
                    className="text-gray-400 font-normal">&euro;</span> 220
                  </div>
                </div>
              </div>
            </div>
            <div className="p-0">
              <div className='h-full grid grid-nogutter align-items-center'>
                <div className="col-4">
                  <div className='pt-4 text-center text-sm'><a
                    className="border-2 border-orange border-round color-orange p-1"
                    href="https://www.mijnboer.be/groentegeweld/inschrijven/pakketten?code=groentegeweld|pakketten-2023-flex-trial-mini"
                    target="_blank">Deze wil ik</a></div>
                </div>
                <div className="col-4">
                  <div className='pt-4 text-center text-sm'><a
                    className="border-2 border-orange border-round color-orange p-1"
                    href="https://www.mijnboer.be/groentegeweld/inschrijven/pakketten?code=pakketten-2023-flex-trial-medium"
                    target="_blank">Deze wil ik</a></div>
                </div>
                <div className="col-4">
                  <div className='pt-4 text-center text-sm'><a
                    className="border-2 border-orange border-round color-orange p-1"
                    href="https://www.mijnboer.be/groentegeweld/inschrijven/pakketten?code=pakketten-2023-flex-trial-large"
                    target="_blank">Deze wil ik</a></div>
                </div>
              </div>
            </div>
          </div>
        </FormulaGrid>
      </div>

      <p className='font-italic text-sm' style={{paddingTop: '8px'}}>
        * Niet elk pakket zal even groot zijn, de prijs is dan ook een gemiddelde.
      </p>

      {/*<div className='mt-8 border-1 border-gray-100 shadow-1 p-2 pb-4'>*/}
      {/*  <FormulaGrid className=''>*/}
      {/*    <div className="p-0 border-gray-400 border-right-1"></div>*/}
      {/*    <div className="p-0">*/}
      {/*      <div className='h-full  grid grid-nogutter justify-content-center align-items-center'>*/}
      {/*        <h1 className='pb-0 mb-0 text-lg sm:text-2xl lg:text-3xl'>Flex <span*/}
      {/*          className="highlight">40</span></h1>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*    <div className="p-0 border-right-1 border-left-1 border-gray-300">*/}
      {/*      <div className='h-full  grid grid-nogutter justify-content-center align-items-center '>*/}
      {/*        <h1 className='pb-0 mb-0 text-lg sm:text-2xl lg:text-3xl'>Flex <span*/}
      {/*          className="highlight">30</span></h1>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*    <div className="p-0">*/}
      {/*      <div className='h-full  grid grid-nogutter justify-content-center align-items-center'>*/}
      {/*        <h1 className='pb-0 mb-0 text-lg sm:text-2xl lg:text-3xl'>Flex <span*/}
      {/*          className="highlight">15</span></h1>*/}
      {/*      </div>*/}
      {/*    </div>*/}

      {/*    <div className="p-0">*/}
      {/*      <div className='h-full text-right border-right-1 border-gray-400 pr-2 font-medium text-lg'>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*    <div className="p-0">*/}
      {/*      <div*/}
      {/*        className='h-full grid grid-nogutter justify-content-center align-items-center font-bold text-lg sm:text-xl md:2xl color-orange'>40 <span*/}
      {/*        className="text-xs sm:text-sm md:text-md ld:text-lg pl-1 text-gray-400 font-normal"> pakketten</span>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*    <div className="p-0 border-right-1 border-left-1 border-gray-300">*/}
      {/*      <div*/}
      {/*        className='h-full grid grid-nogutter justify-content-center align-items-center font-bold text-lg sm:text-xl md:2xl color-orange'>30 <span*/}
      {/*        className="text-xs sm:text-sm md:text-md ld:text-lg pl-1 text-gray-400 font-normal"> pakketten</span>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*    <div className="p-0">*/}
      {/*      <div*/}
      {/*        className='h-full grid grid-nogutter justify-content-center align-items-center font-bold text-lg sm:text-xl md:2xl color-orange'>15 <span*/}
      {/*        className="text-xs sm:text-sm md:text-md ld:text-lg pl-1 text-gray-400 font-normal"> pakketten</span>*/}
      {/*      </div>*/}
      {/*    </div>*/}

      {/*    <div className="p-0">*/}
      {/*      <div*/}
      {/*        className='h-full text-right border-right-1 border-gray-400 pr-2 font-medium text-lg flex justify-content-end align-items-center'>*/}

      {/*      </div>*/}
      {/*    </div>*/}
      {/*    <div className="p-1 pb-0 flex justify-content-center flex-column border-right-1 border-gray-200">*/}
      {/*      <div className='flex flex-column justify-content-center align-items-center'>*/}
      {/*        <div className="text-md md:text-xl lg:text-2xl font-medium color-orange line-height-2">Wekelijks</div>*/}
      {/*        <div className="text-xs sm:text-sm md:text-md color-green line-height-1 pb-2">(februari - maart*/}
      {/*          tweewekelijks)*/}
      {/*        </div>*/}
      {/*        /!*<div className="text-sm text-gray-500 line-height-1 font-italic">januari: geen pakketten</div>*!/*/}
      {/*      </div>*/}

      {/*    </div>*/}
      {/*    <div className="p-1 pb-0 flex justify-content-center flex-column border-right-1 border-gray-200">*/}
      {/*      <div className='flex flex-column justify-content-center align-items-center'>*/}
      {/*        <div className="text-md md:text-xl lg:text-2xl font-medium color-orange line-height-2">Wekelijks</div>*/}
      {/*        <div className="text-xs sm:text-sm md:text-md color-green line-height-1 pb-2">(februari - maart*/}
      {/*          tweewekelijks)*/}
      {/*        </div>*/}
      {/*        /!*<div className="text-sm text-gray-500 line-height-1 font-italic">januari: geen pakketten</div>*!/*/}
      {/*      </div>*/}

      {/*    </div>*/}
      {/*    <div className="p-1 pb-0">*/}
      {/*      <div className='h-full p-1 flex flex-column justify-content-center align-items-center'>*/}
      {/*        <div className="text-md md:text-xl lg:text-2xl font-medium color-orange line-height-2">Tweewekelijks*/}
      {/*        </div>*/}
      {/*        <div className="text-xs sm:text-sm md:text-md color-green line-height-1 pb-2">Heel het jaar</div>*/}
      {/*        /!*<div className="text-sm text-gray-500 line-height-1 font-italic">januari: geen pakketten</div>*!/*/}
      {/*      </div>*/}
      {/*    </div>*/}


      {/*    <div className="p-0">*/}
      {/*    </div>*/}
      {/*    <div className="p-0">*/}
      {/*      <div className="h-full grid grid-nogutter pt-4 pb-2 text-lg font-medium color-green align-items-end">*/}
      {/*        <div className='col-4 text-center text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl'>Mini</div>*/}
      {/*        <div className='col-4 text-center text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl'>Klein</div>*/}
      {/*        <div className='col-4 text-center text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl'>Groot</div>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*    <div className="p-0 border-right-1 border-left-1 border-gray-300">*/}
      {/*      <div className="h-full grid grid-nogutter pt-4 pb-2 text-lg font-medium color-green align-items-end">*/}
      {/*        <div className='col-4 text-center text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl'>Mini</div>*/}
      {/*        <div className='col-4 text-center text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl'>Klein</div>*/}
      {/*        <div className='col-4 text-center text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl'>Groot</div>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*    <div className="p-0">*/}
      {/*      <div className="h-full grid grid-nogutter pt-4 pb-2 text-lg font-medium color-green align-items-end">*/}
      {/*        <div className='col-4 text-center text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl'>Mini</div>*/}
      {/*        <div className='col-4 text-center text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl'>Klein</div>*/}
      {/*        <div className='col-4 text-center text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl'>Groot</div>*/}
      {/*      </div>*/}
      {/*    </div>*/}


      {/*    <div className="p-0">*/}
      {/*      <div*/}
      {/*        className='h-full text-right border-right-1 border-gray-400 pr-2 font-medium text-lg flex  justify-content-end align-items-center'>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*    <div className="p-0">*/}
      {/*      <div className='h-full grid grid-nogutter align-items-center'>*/}
      {/*        <div className='col-4 text-center color-orange text-sm font-bold'><span*/}
      {/*          className="text-gray-400 font-normal text-xs sm:text-sm md:text-md">&euro;</span> 11*/}
      {/*        </div>*/}
      {/*        <div className='col-4 text-center color-orange text-sm font-bold'><span*/}
      {/*          className="text-gray-400 font-normal text-xs sm:text-sm md:text-md">&euro;</span> 18*/}
      {/*        </div>*/}
      {/*        <div className='col-4 text-center color-orange text-sm font-bold'><span*/}
      {/*          className="text-gray-400 font-normal text-xs sm:text-sm md:text-md">&euro;</span> 26*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*    <div className="p-0 border-right-1 border-left-1 border-gray-300">*/}
      {/*      <div className='h-full grid grid-nogutter align-items-center'>*/}
      {/*        <div className='col-4 text-center color-orange text-sm font-bold'><span*/}
      {/*          className="text-gray-400 font-normal text-xs sm:text-sm md:text-md">&euro;</span> 11<span*/}
      {/*          className="text-sm">,50</span></div>*/}
      {/*        <div className='col-4 text-center color-orange text-sm font-bold'><span*/}
      {/*          className="text-gray-400 font-normal text-xs sm:text-sm md:text-md">&euro;</span> 19*/}
      {/*        </div>*/}
      {/*        <div className='col-4 text-center color-orange text-sml font-bold'><span*/}
      {/*          className="text-gray-400 font-normal text-xs sm:text-sm md:text-md">&euro;</span> 27<span*/}
      {/*          className="text-sm">,50</span></div>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*    <div className="p-0">*/}
      {/*      <div className='h-full grid grid-nogutter align-items-center'>*/}
      {/*        <div className='col-4 text-center color-orange text-sm font-bold'><span*/}
      {/*          className="text-gray-400 font-normal text-xs sm:text-sm md:text-md">&euro;</span> 12*/}
      {/*        </div>*/}
      {/*        <div className='col-4 text-center color-orange text-sm font-bold'><span*/}
      {/*          className="text-gray-400 font-normal text-xs sm:text-sm md:text-md">&euro;</span> 19<span*/}
      {/*          className="text-sm">,50</span></div>*/}
      {/*        <div className='col-4 text-center color-orange text-sml font-bold'><span*/}
      {/*          className="text-gray-400 font-normal text-xs sm:text-sm md:text-md">&euro;</span> 28*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    </div>*/}


      {/*    <div className="p-0">*/}
      {/*      <div*/}
      {/*        className='h-full text-right border-right-1 border-gray-400 pr-2 font-medium text-lg flex  justify-content-end align-items-center'>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*    <div className="p-0">*/}
      {/*      <div className='h-full grid grid-nogutter align-items-center'>*/}
      {/*        <div className='col-4 text-center color-green text-md font-medium'><span*/}
      {/*          className="text-gray-400 font-normal">&euro;</span> 435*/}
      {/*        </div>*/}
      {/*        <div className='col-4 text-center color-green text-base font-medium'><span*/}
      {/*          className="text-gray-400 font-normal">&euro;</span> 720*/}
      {/*        </div>*/}
      {/*        <div className='col-4 text-center color-green text-lg font-medium'><span*/}
      {/*          className="text-gray-400 font-normal">&euro;</span> 1040*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*    <div className="p-0 border-right-1 border-left-1 border-gray-300">*/}
      {/*      <div className='h-full grid grid-nogutter align-items-center'>*/}
      {/*        <div className='col-4 text-center color-green text-md font-medium'><span*/}
      {/*          className="text-gray-400 font-normal">&euro;</span> 340*/}
      {/*        </div>*/}
      {/*        <div className='col-4 text-center color-green text-base font-medium'><span*/}
      {/*          className="text-gray-400 font-normal">&euro;</span> 570*/}
      {/*        </div>*/}
      {/*        <div className='col-4 text-center color-green text-lg font-medium'><span*/}
      {/*          className="text-gray-400 font-normal">&euro;</span> 820*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*    <div className="p-0">*/}
      {/*      <div className='h-full grid grid-nogutter align-items-center'>*/}
      {/*        <div className='col-4 text-center color-green text-md font-medium'><span*/}
      {/*          className="text-gray-400 font-normal">&euro;</span> 180*/}
      {/*        </div>*/}
      {/*        <div className='col-4 text-center color-green text-base font-medium'><span*/}
      {/*          className="text-gray-400 font-normal">&euro;</span> 295*/}
      {/*        </div>*/}
      {/*        <div className='col-4 text-center color-green text-lg font-medium'><span*/}
      {/*          className="text-gray-400 font-normal">&euro;</span> 420*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    </div>*/}

      {/*    <div className="p-0 pt-2">*/}
      {/*      <div*/}
      {/*        className='h-full text-right border-right-1 border-gray-400 pr-2 font-medium text-md flex  justify-content-center flex-column align-items-end'>Proefabonnement <span*/}
      {/*        className="font-italic">(Probeer 8 weken)</span>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*    <div className="p-0 pt-2">*/}

      {/*    </div>*/}
      {/*    <div className="p-0 pt-2">*/}
      {/*      <div className='h-full grid grid-nogutter align-items-center'>*/}
      {/*        <div className='col-4 text-center color-green text-md font-medium'><span*/}
      {/*          className="text-gray-400 font-normal">&euro;</span> 92<span className="text-sm">,50</span>*/}
      {/*        </div>*/}
      {/*        <div className='col-4 text-center color-green text-md font-medium'><span*/}
      {/*          className="text-gray-400 font-normal">&euro;</span> 155*/}
      {/*        </div>*/}
      {/*        <div className='col-4 text-center color-green text-md font-medium'><span*/}
      {/*          className="text-gray-400 font-normal">&euro;</span> 225*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*    <div className="p-0 pt-2">*/}

      {/*    </div>*/}

      {/*  </FormulaGrid>*/}
      {/*</div>*/}


    </Content>
    <CallToAction type={'pakketten'} label={'Schrijf mij in voor een flex-pakket'}/>
    <Parallax
      img={'IMG_7519.jpg'}
      mobileImg={'IMG_7519.jpg'}
      minHeight={'300px'}
      height={'300px'}
    />
    <Content>

      <Grid columns={'1fr 2fr'} style={{alignItems: "center"}}>
        <div>
          <img alt={'extras'} src={'potato-onion.png'} style={{mixBlendMode: "multiply"}}/>
        </div>

        <div>
          <h1>En er is meer...</h1>
          <p>
            Basisproducten zoals <strong>eieren, aardappelen of uien</strong> kan je ook bij ons bestellen. We
            produceren ze meestal niet op eigen veld, we laten dit over aan bevriende bioboeren uit de buurt. Door ook
            dit in je pakket te steken, maak je opnieuw voor heel wat producten de keten korter. <br/>Bovendien heb je
            deze basisproducten ook steeds in huis. Je kan uiteraard de hoeveelheden aanpassen naar je gezinssituatie!
          </p>
        </div>
      </Grid>
    </Content>
    <CallToAction type={'pakketten'} label={'Schrijf mij in voor een flex-pakket'}/>
    <Parallax
      img={'IMG_7519.jpg'}
      mobileImg={'IMG_7519.jpg'}
      minHeight={'300px'}
      height={'300px'}
    />


    {/*<Questions>*/}
    {/*  <h3>Zit je nog met vragen?</h3>*/}
    {/*  <p>Aarzel niet om ons te <a href={'mailto:info@groentegeweld.be'}>contacteren</a>.</p>*/}
    {/*</Questions>*/}

    <Footer/>
  </div>
    ;
};

export default BasketsPageNew;
