import React from 'react';
import BasketPageSection from "./BasketPageSection";
import DeliveryLocations from "../../../SubscriptionWizard/components/FormulaStep/components/DeliveryLocations";
import CallToAction from "../CallToAction";

interface DeliverySectionProps {
}

const DeliverySection = (props: DeliverySectionProps) => {
  return (
    <>
      <BasketPageSection image='pakket-omloop.jpg' gradientPosition='right' title='Kies zelf'
                         subTitle='een afhaalpunt in de buurt'>
        <div className="color-orange text-xl">Altijd een afhaalpunt in je buurt</div>
        <p>Onze pakketten worden op <strong>vrijdag</strong> (namiddag) geleverd in het afhaalpunt. </p>
        <p>Jij kiest zelf je vaste afhaalpunt. Wil je toch een keer je pakket op een ander afhaalpunt afhalen dan kan je
          dit eenvoudig zelf doorgeven via de webapp.</p>
        <div style={{marginTop: "32px"}}>
          <DeliveryLocations static={true} hideAddress/>

        </div>
      </BasketPageSection>
      <CallToAction type={'pakketten'} label={'Schrijf mij in voor een flex-pakket'}/>
    </>
  );
};

export default DeliverySection;
