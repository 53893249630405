import React from 'react';
import BasketPageSection from "./BasketPageSection";
import styled from "styled-components";

interface DateSectionProps {
}

const Formulas = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 1fr;
  @media screen and (min-width: 576px) {grid-template-columns: 1fr 1fr;}
  @media screen and (min-width: 768px) {grid-template-columns: 1fr 1fr;}
  @media screen and (min-width: 992px) {grid-template-columns: 1fr 1fr 1fr;}
`;


const DateSection = (props: DateSectionProps) => {
  return (
    <BasketPageSection image='pakketten-vakantie3.jpg' gradientPosition='right' title='Kies zelf'
                       subTitle='wanneer je op vakantie gaat'>

      <p className="color-orange text-lg">Welke formule past het best bij jou?</p>

      <Formulas className="justify-content-center mb-4">
        <div className="border-orange-100 border-1 shadow-1 flex justify-content-between flex-column">
          <div className="p-2">
            <div className="text-2xl"><span className="font-medium color-green">Flex</span><span
              className="color-orange font-bold">40</span></div>
            <div>
              <span className='font-medium text-lg'>40 pakketten</span> <span
              className="text-gray-500 text-md">Wekelijks</span>
            </div>
            <div>Perfect om het hele jaar door te genieten.</div>
          </div>
          {/*<div className='p-2 grid line-height-2 grid-nogutter'>*/}
          {/*  <div className='col-4'>Mini</div>*/}
          {/*  <div className='col-8'><span className="text-gray-500">&euro;</span> <span className="color-orange font-medium text-lg">435</span></div>*/}
          {/*  <div className='col-4'>Klein</div>*/}
          {/*  <div className='col-8'><span className="text-gray-500">&euro;</span> <span className="color-orange font-medium text-lg">720</span></div>*/}
          {/*  <div className='col-4'>Groot</div>*/}
          {/*  <div className='col-8'><span className="text-gray-500">&euro;</span> <span className="color-orange font-medium text-lg">1040</span></div>*/}
          {/*</div>*/}
          {/*<div className="flex justify-content-end align-items-end p-2">*/}
          {/*  <a className="px-3 py-1 bg-orange text-white overflow-hidden md:text-lg uppercase border-round"*/}
          {/*     href="https://www.mijnboer.be/groentegeweld/inschrijven/pakketten">Schrijf me in</a>*/}
          {/*</div>*/}
        </div>
        <div className="border-orange-100 border-1 shadow-1 flex justify-content-between flex-column">
          <div className="p-2">
            <div className="text-2xl"><span className="font-medium color-green">Flex</span><span
              className="color-orange font-bold">30</span></div>
            <div>
              <span className='font-medium text-lg'>30 pakketten</span> <span
              className="text-gray-500 text-md">Wekelijks</span>
            </div>
            <div>Met de flexibiliteit om een seizoen over te slaan.</div>
          </div>
          {/*<div className='p-2 grid line-height-2 grid-nogutter'>*/}
          {/*  <div className='col-4'>Mini</div>*/}
          {/*  <div className='col-8'><span className="text-gray-500">&euro;</span> <span className="color-orange font-medium text-lg">340</span></div>*/}
          {/*  <div className='col-4'>Klein</div>*/}
          {/*  <div className='col-8'><span className="text-gray-500">&euro;</span> <span className="color-orange font-medium text-lg">570</span></div>*/}
          {/*  <div className='col-4'>Groot</div>*/}
          {/*  <div className='col-8'><span className="text-gray-500">&euro;</span> <span className="color-orange font-medium text-lg">820</span></div>*/}
          {/*</div>*/}
          {/*<div className="flex justify-content-end align-items-end p-2">*/}
          {/*  <a className="px-3 py-1 bg-orange text-white overflow-hidden md:text-lg uppercase border-round"*/}
          {/*     href="https://www.mijnboer.be/groentegeweld/inschrijven/pakketten">Schrijf me in</a>*/}
          {/*</div>*/}
        </div>
        <div className="border-orange-100 border-1 shadow-1 flex justify-content-between flex-column">
          <div className="p-2">
            <div className="text-2xl"><span className="font-medium color-green">Flex</span><span
              className="color-orange font-bold">15</span></div>
            <div>
              <span className='font-medium text-lg'>15 pakketten</span> <span
              className="color-orange text-md font-medium">Tweewekelijks</span>
            </div>
            <div>Ideaal om af te wisselen met je eigen moestuin of andere boodschappen.</div>
          </div>
          {/*<div className='p-2 grid line-height-2 grid-nogutter'>*/}
          {/*  <div className='col-4'>Mini</div>*/}
          {/*  <div className='col-8'><span className="text-gray-500">&euro;</span> <span className="color-orange font-medium text-lg">180</span></div>*/}
          {/*  <div className='col-4'>Klein</div>*/}
          {/*  <div className='col-8'><span className="text-gray-500">&euro;</span> <span className="color-orange font-medium text-lg">295</span></div>*/}
          {/*  <div className='col-4'>Groot</div>*/}
          {/*  <div className='col-8'><span className="text-gray-500">&euro;</span> <span className="color-orange font-medium text-lg">420</span></div>*/}
          {/*</div>*/}
          {/*<div className="flex justify-content-end align-items-end p-2">*/}
          {/*  <a className="px-3 py-1 bg-orange text-white overflow-hidden md:text-lg uppercase border-round"*/}
          {/*     href="https://www.mijnboer.be/groentegeweld/inschrijven/pakketten">Schrijf me in</a>*/}
          {/*</div>*/}

        </div>
      </Formulas>

      <p>In <span className="font-medium">februari en maart</span> leveren we voor iedereen tweewekelijkse
        pakketten. <br/>
        Vanaf de <span className="font-medium">kerstvakantie tot eind januari</span> leveren we geen pakketten.</p>

      <p>Groentegeweld staat voor <span className="font-bold">seizoensgebonden eten, het hele jaar door</span>. Daarom zijn onze <span className="font-bold">langdurige abonnementen
        per pakket voordeliger</span>. Door jouw grotere betrokkenheid ervaar je de seizoenen optimaal en geef je de boer de
        zekerheid die hij nodig heeft!</p>

      <div className="mt-4 border-1 border-orange border-round-md overflow-hidden border-left-3">
        <div className="p-3">
          <p className="text-lg font-medium color-orange mb-0">Op vakantie of gewoon een te drukke week?</p>
          <p> Geen probleem! <br/> <span className="font-bold color-green">Stel je pakket eenvoudig uit</span> via de
            webapp
            en schuif
            het door naar het einde van je abonnement. Zo mis je niets
            en blijf je langer genieten van onze pure, verse smaken.</p>
        </div>

      </div>

    </BasketPageSection>
  );
};

export default DateSection;
