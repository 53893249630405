import React, {ReactNode} from 'react';
import {Content} from "../../../../App";

interface BasketPageSectionProps {
  image: string;
  gradientPosition: 'left' | 'right';
  title: string;
  subTitle: string;
  children: ReactNode;
}

const BasketPageSection = (props: BasketPageSectionProps) => {
  return (
    <>
      <div className="image-container h-30rem" style={{
        backgroundImage: `url('${props.image}')`,
        maxHeight: '40vh',
      }}>
        <div className="overlay" style={{background: `linear-gradient(to ${props.gradientPosition}, white, transparent)`}}></div>
        <div className="absolute bottom-0 left-0 right-0 z-2">
          <Content className='h-full'>
            <div className="h-full flex align-items-end">
              <div className="text-2xl md:text-4xl font-bold">
                <div className='bg-white color-orange px-3 p-2 md:p-3 w-fit'>{props.title}</div>
                <div className='bg-orange text-white px-3 p-2 md:p-3 w-fit'>{props.subTitle}</div>

              </div>
            </div>
          </Content>
        </div>
      </div>
      <Content className="mt-3 mb-6">
        {props.children}
      </Content>
    </>
  );
};

export default BasketPageSection;
