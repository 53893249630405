import React from 'react';
import { Content } from "../../App";
import styled from "styled-components";
import { Button } from "primereact/button";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer";
import { StringParam, useQueryParam } from "use-query-params";
import {momentFromDdMmYyyy} from "../../shared/utils/date.utils";
import moment from "moment";

interface ShopInfoProps {

}

const How = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
  grid-gap: 1em;
  margin: 1em;

  > div {
    counter-increment: section;                 /* Increment the value of section counter by 1 */
  }
`;

const ActionButton = styled(Button)`
  &.p-button,
  &.p-button:enabled:active,
  &.p-button:enabled:hover,
  &.p-button:enabled:focus,
  &.p-button:enabled:visited {
    background-color: var(--orange);

    span {
      font-size: 150%;
      color: white;
    }
  }
`;

const Item = styled.div`
  border: 2px solid var(--orange);
  padding: 1vw;
  color: var(--green);
  position: relative;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  &.numbered::before {
    content: counter(section);
    background-color: var(--orange);
    position: absolute;
    font-size: 3vw;
    text-align: center;
    width: 4vw;
    height: 4vw;
    color: white;
    font-weight: bold;
    top: 0;
    left: 0;
  }

  text-align: center;

  i {
    font-size: 8vw;
    color: var(--orange-40);
  }

  img {
    height: 175px;
  }
`;

const ShopInfo = (props: ShopInfoProps) => {
  const [emailParam] = useQueryParam('email', StringParam);

  let linkToShop = '/veldwinkel/bestellen';
  if (emailParam) {
    linkToShop += `?email=${emailParam}`;
  }

  // const disableLink = moment().isAfter(momentFromDdMmYyyy("20-12-2024"), 'day');
  const disableLink = false;
  return <div>
    <Content>
      {disableLink && <div className="p-2 m-3 border-2 border-orange-500 bg-orange-100 border-round-md">
        <div className="text-xl font-bold text-orange-500">Winterrust, veldwinkel gesloten tot vrijdag 7 februari</div>
        <div className="text-sm">Tijdens deze winterperiode nemen we even rust op het veld en maken we ons klaar voor
          het nieuwe jaar.
        </div>
        <div className="text-base mt-2">Vanaf <strong>7 februari</strong> is onze online veldwinkel terug open voor
          jullie.
        </div>
        <div className="mt-3">Prettige feestdagen! <br/>Dirk & Willem</div>
      </div>}


      <h1>Welkom <span>in onze veldwinkel</span></h1>
      <p>Fijn dat je kiest voor <strong>lokale en biologische</strong> groenten, recht van het veld! In Weelde kweken
        Dirk en Willem al
        enkele jaren de meest verse groenten. Vanaf nu bieden we ook een selectie aan in de <strong>online
          veldwinkel</strong>. Bestel ze
        hier en haal ze af bij jou in de buurt!
        <br/>
        <br/>
        Smakelijk
        <br/>
        Dirk en Willem
      </p>


      <div style={{textAlign: 'center'}}>
        <Link to={linkToShop}>
          <ActionButton disabled={disableLink} className={'p-button'} label={'Naar de online veldwinkel'}/>
        </Link>
      </div>

      {/*<h2>Ons aanbod</h2>*/}
      {/*<How className={'numbered'}>*/}
      {/*  <Item>*/}
      {/*    Groenten en kruiden van ons eigen veld.*/}
      {/*    <img src={'IMG_7591.jpeg'}/>*/}
      {/*  </Item>*/}
      {/*  <Item>*/}
      {/*    Aardappelen, uien en soms kaas of bloemen van bevriende (bio)boeren vlakbij ons veld (straal van max 20 km).*/}
      {/*    <img src={'shop_other.jpeg'}/>*/}
      {/*  </Item>*/}
      {/*</How>*/}

      <h2>Hoe werkt het?</h2>
      <How>
        <Item className={'numbered'}><i className="icon-wheelbarrow"></i>
          <div>Kies welke groenten of kruiden je wil en zet ze in je oogstmand.</div>
        </Item>
        <Item className={'numbered'}><i className="icon-profile"></i>
          <div>Vul je gegevens in.</div>
        </Item>
        <Item className={'numbered'}><i className="icon-location"></i>
          <div>Kies een afhaalmoment een afhaalpunt.</div>
        </Item>
        <Item className={'numbered'}><i className="icon-euro"></i>
          <div>Betaal per overschrijving; gebruik hierbij de juiste mededeling.</div>
        </Item>
        <Item className={'numbered'}><i className="icon-harvest-box"></i>
          <div>Haal je bestelling af en geniet van vers en lokaal in je keuken!</div>
        </Item>
      </How>

      <div style={{textAlign: 'center'}}>
        <Link to={linkToShop}>
          <ActionButton disabled={disableLink} className={'p-button'} label={'Naar de online veldwinkel'}/>
        </Link>
      </div>

      <br/>
      <br/>
      <p>Geen zin in wekelijks zelf te bestellen? <br/>
        Ontdek <Link to={'/pakketten'}>hier</Link> alles over onze pakketten.
      </p>
      <br/>
      <br/>

    </Content>
    <Footer/>
  </div>;
};

export default ShopInfo;
