import React from 'react';
import styled from "styled-components";
import { capitalize, get, last } from "lodash";
import classNames from "classnames";
import { formatCurrencySign, formatDoubleDigit } from "../../../../src/shared/utils/currency.utils";
import { Button } from "primereact/button";
import { FormulaPeriod, SubscriptionSubType } from "../../../shared/types/types";
import { BasketType, DeliveryLocation, GetProductsForSubscription_products } from "../../../__generated__/types";
import { useNavigate } from "react-router-dom";
import { Moment } from "moment";
import PeriodTimelineDense from "../../SubscriptionWizard/components/FormulaStep/components/PeriodTimelineDense";

import { deliveryWeeksForSubscription } from "../../../../src/shared/utils/subscription.utils";

interface FormulaProps {
  period: FormulaPeriod,
  idx: number,
  type: string,
  products: GetProductsForSubscription_products[];
  onClick?: () => void;
  value?: string | undefined;
  basketType?: BasketType | undefined;
  lastBasketDay?: Moment;
  lastPossibleBasketDay?: Moment;
  hideButton?: boolean;
}

const Container = styled.div`
  display: grid;
  grid-template-areas: "title"
  "description"
  "sizes"
  "duration";
  grid-template-rows: max-content 1fr max-content max-content;
  grid-template-columns: 1fr;
  grid-gap: 8px 0;
  padding: 16px 0;
  box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%);
  background-color: var(--surface-a);

  border-bottom: 1px solid var(--green-40);
  border: 1px solid var(--green-40);
  //&:last-child {
  //  border: 0;
  //}

  @media only screen and (min-width: 750px) {
    grid-template-rows: minmax(120px, 150px) 1fr max-content;

    //border-bottom: 0;
    //&:last-child {
    //  border: 0;
    //}
  }



  &.interactive {
    cursor: pointer;

    &:hover {
      background-color: var(--green-20);
      border-color: var(--green);
    }
  }

  &.active {
    background-color: var(--green-20);
    border-color: var(--green);
  }

  &.small {
    font-size: 70%;
  }

  .biWeekly {
    color: var(--brown);

    .extra {
      font-size: 80%;
      font-style: italic;
    }
  }

  .title {
    align-self: end;
    text-align: center;
  }

  h1 {
    text-align: center;
    margin: 0;
    padding: 0;

    .highlight {
      font-size: 150%;
    }
  }

  .general {
    justify-self: center;
  }

  .sizes {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 8px 0;
    text-align: center;
    font-size: 120%;
    border-right: 1px solid var(--green-20);

    &:last-child {
      border: 0;
    }

    .active {
      border: 1px solid var(--green);
      background-color: var(--green-20);
    }

    .price {
      color: var(--green);
    }

    .price,
    .weekPrice {

      .currency {
        font-size: 80%
      }
    }

    .weekPrice {
      font-size: 70%;
      white-space: nowrap;
    }

    h4 {
      margin-bottom: 0;
    }

    .description {
      margin: 0;
      padding: 0;
      font-size: 0.7em;
      padding-bottom: 16px;
    }

    .p-button {
      margin-top: 8px;
      background-color: white;
      border: 1px solid var(--orange);
      color: var(--orange);
    }
  }

`;

const Formula = (props: FormulaProps) => {
  const {products, period, type, idx} = props;

  let sizes = [
    {
      type: BasketType.MINI,
      code: 'mini',
      label: 'Mini',
      description: 'Voor 1',
      price: 0,
      pricePerBasket: 0
    },
    {
      type: BasketType.STANDARD,
      code: 'small',
      label: 'Klein',
      description: 'Voor 1-2 pers.',
      price: 0,
      pricePerBasket: 0
    },
    {type: BasketType.LARGE, code: 'large', label: 'Groot', description: 'Voor je gezin', price: 0, pricePerBasket: 0},
  ]
    .filter(basketSize => (props.basketType && basketSize.type === props.basketType) || !props.basketType)
    .map(basketSize => {
      let product = products
        .find((p) => p.code === get(period, `code${capitalize(basketSize.code)}`));
      let price = (last(product?.productPrices)?.value || 0) * 1.06;
      return {
        ...basketSize,
        price,
        pricePerBasket: price / period.nrOfWeeks,
        productCode: product?.code,
      }
    });


  let selectedSize = sizes.find(size => size.type === props.basketType);
  return <Container
    className={classNames({
      small: period.codeSmall && period.codeSmall.indexOf('trial') > -1,
      interactive: props.onClick !== undefined,
      active: props.value
        ? [period.codeSmall, period.codeLarge, period.code].indexOf(props.value) > -1
        : false,
    })}
    onClick={() => {
      return props.onClick && props.onClick();
    }}
  >
    <div className={'title'} style={{gridArea: 'title'}}>
      <h1 style={{fontSize: `${3 - (idx * 0.3)}em`}}>
        {/*{*/}
        {/*  period.codeSmall && period.codeSmall.indexOf('trial') > -1*/}
        {/*    ? <span>proef</span>*/}
        {/*    : <>{type} <span className="highlight">{period.nrOfWeeks}</span></>*/}
        {/*}*/}
        {type} <span className="highlight">{period.nrOfWeeks}</span>
      </h1>
      {period.codeSmall && period.codeSmall.indexOf('flex') > -1
        ? <span>max. {period.nrOfWeeks} pakketten in {period.nrOfPossibleWeeks} mogelijke pakketweken </span>
        : <span>{period.nrOfWeeks} {!period.biWeekly && 'opeenvolgende'} pakketten</span>
      }
      {!period.biWeekly &&
        <div className="biWeekly">
          Wekelijks<br/><span className="extra">(januari: geen pakketten, februari-maart: tweewekelijks)</span>
        </div>
      }
      {period.biWeekly && <div className="biWeekly"><strong>Tweewekelijks</strong><br/><span className="extra">(januari: geen pakketten)</span>
      </div>}
    </div>
    <div className={'general'} style={{gridArea: 'description'}}>
      {/*<PeriodTimelineDense*/}
      {/*  membershipSubType={props.type === 'vast' ? SubscriptionSubType.BASKETS_FIXED : SubscriptionSubType.BASKETS_FLEX}*/}
      {/*  subscriptionProductCode={period.codeSmall}*/}
      {/*  deliveryLocation={DeliveryLocation.WEELDE}*/}
      {/*  deliveryWeeks={deliveryWeeksForSubscription(period.codeSmall)}*/}
      {/*  labels={{active: 'Gepland pakket', possible: 'Mogelijke pakketlevering'}}*/}
      {/*  selfHarvestDeliveryIsoWeekDay={1}*/}
      {/*/>*/}
    </div>

    <div className="sizes" style={{gridArea: 'sizes'}}>

      {/*{props.basketType &&*/}
      {/*  <div>*/}
      {/*    <h4>{selectedSize?.label}</h4>*/}
      {/*    <div className={'price'}>*/}
      {/*      <strong><span className={'currency'}>EUR</span> {selectedSize && formatDoubleDigit(selectedSize.price)}*/}
      {/*      </strong>*/}
      {/*    </div>*/}
      {/*    <div className={'weekPrice'}>*/}
      {/*      ({selectedSize && formatCurrencySign(selectedSize.pricePerBasket)} / pakket) **/}
      {/*    </div>*/}
      {/*  </div>}*/}

      {((props.basketType && selectedSize) ? [selectedSize] : sizes).map((size) => {
        return <div key={size.code} style={{gridColumn: selectedSize ? '1 / span 2' : 'auto'}} className={classNames({
          // active: props.value === size.productCode,
          // inactive: props.value && props.value !== size.productCode,
        })}>
          <h4>{size.label}</h4>
          <p className={'description'}>{size.description}</p>

          <div className={'price'}>
            <strong><span className={'currency'}>EUR</span> {formatDoubleDigit(size.price)}</strong>
          </div>
          <div className={'weekPrice'}>
            ({formatCurrencySign(size.pricePerBasket)} / pakket) *
          </div>
          {!props.hideButton && <Button
            label={'Deze wil ik!'}
            onClick={() => {
              window.location.href = `https://www.mijnboer.be/groentegeweld/inschrijven/pakketten?code=`+size.productCode;
            }}
          />}
        </div>;
      })}
    </div>

  </Container>;
};

export default Formula;
