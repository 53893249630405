import React from 'react';
import BasketPageSection from "./BasketPageSection";
import styled from "styled-components";

interface QuantitySectionProps {
}

const Formulas = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 1fr;
  @media screen and (min-width: 576px) {grid-template-columns: 1fr 1fr;}
  @media screen and (min-width: 768px) {grid-template-columns: 1fr 1fr;}
  @media screen and (min-width: 992px) {grid-template-columns: 1fr 1fr 1fr;}
`;


const QuantitySection = (props: QuantitySectionProps) => {
  return (
    <BasketPageSection image='pakketten-vrienden2.jpg' gradientPosition='left' title='Kies zelf'
                       subTitle='hoeveel vrienden je uitnodigt'>


      <p className="text-lg color-orange">Welk pakket past bij jou?</p>
      <p>Ben jij een echte groenteliefhebber, wil je nieuwe smaken ontdekken of gezonde gerechten op tafel zetten voor
        het hele gezin? Wij hebben voor iedereen een geschikt pakket.</p>
      <div className="gap-4 align-items-center">
        <Formulas className="gap-4 justify-content-center">
          <div className='border-orange-100 border-1 shadow-1'>
            <div className="p-2">
              <div className='flex justify-content-between align-items-center'>
                <div className='color-green font-light text-2xl'><span className="font-bold text-4xl">Mini</span> pakket
                </div>
                <div className="color-orange text-base"><span className="font-bold text-lg">4-5</span> groenten</div>
              </div>
              <div className='text-center text-md pt-3 font-medium'><span className="text-gray-600 line-height-1">+/- 4-5 maaltijden <br/> <span
                className="font-medium text-xl">Voor <span className="font-bold">1 persoon</span></span></span>
              </div>

            </div>
            {/*<div className='flex justify-content-between mt-2 align-items-end p-2'>*/}
            {/*  <div>*/}
            {/*    <div className=''>*/}
            {/*      <span className="text-gray-500 text-xs">&plusmn;</span>*/}
            {/*      <span className="font-bold color-orange text-lg"> &euro; 11</span>*/}
            {/*      <span className="text-xs"> / week</span>*/}
            {/*    </div>*/}
            {/*    <div className=''>*/}
            {/*      <span className="text-gray-500 text-xs">&plusmn;</span>*/}
            {/*      <span className="font-medium color-orange text-md"> &euro; 435</span>*/}
            {/*      <span className="text-xs"> / jaar</span>*/}
            {/*    </div>*/}
            {/*  </div>*/}

            {/*  <a className="px-3 py-1 bg-orange text-white overflow-hidden md:text-lg uppercase border-round"*/}
            {/*     href="https://www.mijnboer.be/groentegeweld/inschrijven/pakketten?size=MINI">Schrijf me in</a>*/}
            {/*</div>*/}
          </div>

          <div className='border-orange-100 border-1 shadow-1'>
            <div className="p-2">
              <div className='flex justify-content-between align-items-center'>
                <div className='color-green font-light text-2xl'><span className="font-bold text-4xl">Klein</span> pakket
                </div>
                <div className="color-orange text-base"><span className="font-bold text-lg">5-7</span> groenten</div>
              </div>
              <div className='text-center text-md pt-3 font-medium'><span className="text-gray-600 line-height-1">+/- 4-5 maaltijden <br/> <span
                className="font-medium text-xl">Voor <span className="font-bold">2 personen</span></span></span>
              </div>

            </div>
            {/*<div className='flex justify-content-between mt-2 align-items-end p-2'>*/}
            {/*  <div>*/}
            {/*    <div className=''>*/}
            {/*      <span className="text-gray-500 text-xs">&plusmn;</span>*/}
            {/*      <span className="font-bold color-orange text-lg"> &euro; 18</span>*/}
            {/*      <span className="text-xs"> / week</span>*/}
            {/*    </div>*/}
            {/*    <div className=''>*/}
            {/*      <span className="text-gray-500 text-xs">&plusmn;</span>*/}
            {/*      <span className="font-medium color-orange text-md"> &euro; 720</span>*/}
            {/*      <span className="text-xs"> / jaar</span>*/}
            {/*    </div>*/}
            {/*  </div>*/}

            {/*  <a className="px-3 py-1 bg-orange text-white overflow-hidden md:text-lg uppercase border-round"*/}
            {/*     href="https://www.mijnboer.be/groentegeweld/inschrijven/pakketten?size=STANDARD">Schrijf me in</a>*/}
            {/*</div>*/}
          </div>

          <div className='border-orange-100 border-1 shadow-1'>
            <div className="p-2">
              <div className='flex justify-content-between align-items-center'>
                <div className='color-green font-light text-2xl'><span className="font-bold text-4xl">Groot</span> pakket
                </div>
                <div className="color-orange text-base"><span className="font-bold text-lg">7-9</span> groenten</div>
              </div>
              <div className='text-center text-md pt-3 font-medium'><span className="text-gray-600 line-height-1">+/- 4-5 maaltijden <br/> <span
                className="font-medium text-xl">Voor <span className="font-bold">je hele gezin</span></span></span>
              </div>

            </div>
            {/*<div className='flex justify-content-between mt-2 align-items-end p-2'>*/}
            {/*  <div>*/}
            {/*    <div className=''>*/}
            {/*      <span className="text-gray-500 text-xs">&plusmn;</span>*/}
            {/*      <span className="font-bold color-orange text-lg"> &euro; 26</span>*/}
            {/*      <span className="text-xs"> / week</span>*/}
            {/*    </div>*/}
            {/*    <div className=''>*/}
            {/*      <span className="text-gray-500 text-xs">&plusmn;</span>*/}
            {/*      <span className="font-medium color-orange text-md"> &euro; 1 040</span>*/}
            {/*      <span className="text-xs"> / jaar</span>*/}
            {/*    </div>*/}
            {/*  </div>*/}

            {/*  <a className="px-3 py-1 bg-orange text-white overflow-hidden md:text-lg uppercase border-round"*/}
            {/*     href="https://www.mijnboer.be/groentegeweld/inschrijven/pakketten?size=LARGE">Schrijf me in</a>*/}
            {/*</div>*/}
          </div>
        </Formulas>

        <div className="mt-4 border-1 border-orange border-round-md p-3 border-left-3">
          <p className="text-lg font-medium color-orange mb-0">Vrienden op bezoek?</p>
          <p className="text-gray-500"><span className="font-medium color-green">Vergroot eenvoudig je pakket</span>,
            zodat iedereen mee kan genieten van de heerlijke versheid in jouw
            gerechten.</p>

          <p className="text-lg font-medium color-orange mb-0">Of mag je zelf op bezoek?</p>
          <p className="text-gray-500 mb-0"><span
            className="font-medium color-green">Maak je pakket die week wat kleiner</span>, zodat je niets hoeft te
            verspillen.</p>
        </div>

      </div>


    </BasketPageSection>
  );
};

export default QuantitySection;
