import React, { FunctionComponent, useEffect } from 'react';
import 'antd/dist/antd.css';
import { Link, Route, useLocation, useNavigate, redirect } from 'react-router-dom';
import styled from "styled-components";
import Page from "./shared/components/soft-farms-bootstrap";
import Home from "./pages/home";
import { get } from 'lodash';
import Billing from "./pages/Billing/Billing";
import Horeca from 'pages/Horeca/Horeca';
import { Button } from "primereact/button";
import HarvestList from 'pages/HarvestList/HarvestList';
import SubscriptionWizard from "./pages/SubscriptionWizard/SubscriptionWizard";
import BasketsPage from "./pages/BasketsPage/BasketsPage2";
import SelfHarvestPage from 'pages/SelfHarvestPage/SelfHarvestPage';
import CsaPage from 'pages/CsaPage/CsaPage';
import { Menubar } from "primereact/menubar";
import Shop from "./pages/Shop/Shop";
import ShopInfo from "./pages/Shop/ShopInfo";
import classNames from "classnames";
import OogstfeestPage from "./pages/OogstfeestPage/OogstfeestPage";
import AccountPage from "./pages/AccountPage/AccountPage";
import { useAuth0 } from "@auth0/auth0-react";
import { useLazyQuery } from "@apollo/react-hooks";
import { CheckUser } from "./__generated__/types";
import { CheckUserQuery } from "./shared/queries.gql";
import appConfig from "./config.app";
import { Routes } from "react-router";
import UserMenu from "./shared/components/UserMenu/UserMenu";
import Potluck from "./pages/Potluck/Potluck";
import GeneralConditionsPage from "./pages/GeneralConditionsPage/GeneralConditionsPage";
import CampaignPage from 'pages/CampaignPage/CampaignPage';
import ReactGA from "react-ga4";
import { RemoveTrailingSlash } from "./RemoveTrailingSlash";
import Oogstfeest2024Page from "./pages/Oogstfeest2024Page/Oogstfeest2024Page";
import BasketsPageNew from "./pages/BasketsPage/BasketsPage3";

const ROUTES = (loginWithRedirect?: any, logout?: any) => [
  {
    path: "/",
    component: Home,
    //showOpendeur: true,
    menu: {
      title: 'Home',
    },
    feest: false,
    headerOptions: {
      height: '60',
      //height: '5vh',
      img: 'IMG_1658-landscape.jpeg',
    }
  },
  {
    path: "/voorwaarden",
    component: GeneralConditionsPage,
    hidden: true,
    menu: {
      title: 'Voorwaarden',
    },
  },
  {
    path: "/csa",
    // showOpendeur: true,
    component: CsaPage,
    feest: false,
    menu: {
      title: 'CSA',
    },
  },
  // {
  //   path: "/feest",
  //   // showOpendeur: true,
  //   component: Oogstfeest2024Page,
  //   menu: {
  //     title: 'Feest',
  //   },
  // },
  // {
  //   path: "/oogstfeest",
  //   //showOpendeur: true,
  //   component: OogstfeestPage,
  //   menu: {
  //     title: 'Oogstfeest',
  //   },
  //   // headerOptions: {
  //   //   height: '40',
  //   //   //height: '5vh',
  //   //   img: 'IMG_7886.jpg',
  //   // }
  // },
  {
    path: "/zelfoogst",
    //showOpendeur: true,
    feest: false,
    component: SelfHarvestPage,
    menu: {
      title: 'Zelfoogst',
    },
  },
  {
    path: "/pakketten-oud",
    //showOpendeur: true,
    feest: false,
    menu: {
      title: 'Pakketten',
    },
    //roles: ["customer_horeca"],
    component: () => <BasketsPage/>,
  },
  {
    path: "/pakketten",
    //showOpendeur: true,
    feest: false,
    hidden: true,
    menu: {
      title: 'Pakketten',
    },
    //roles: ["customer_horeca"],
    component: () => <BasketsPageNew/>,
  },
  // {
  //   path: "/inschrijven",
  //   component: () => <Signup/>,
  //   menu: {
  //     title: 'Word deelnemer',
  //   },
  // },
  {
    hidden: true,
    path: "/facturatie",
    component: () => <Billing/>,
    menu: {
      title: 'Facturatie',
    },
  },
  {
    hidden: true,
    path: "/lekkerstebuurt",
    component: () => <CampaignPage/>,
    menu: {
      title: 'Lekkerste buurt',
    },
  },
  {
    hidden: true,
    path: "/potluck",
    component: () => <Potluck/>,
  },
  {
    hidden: true,
    // menu: {
    //   title: 'Horeca',
    // },
    feest: false,
    path: "/horeca",
    //roles: ["customer_horeca"],
    component: () => <Horeca clientGroup={'ckbuxfzgg07x30774zagfq0x1'}/>,
    menu: {
      title: 'Aanbod: horeca',
    },
  },
  {
    hidden: true,
    // menu: {
    //   title: 'Horeca',
    // },
    feest: false,
    path: "/detailhandel",
    //roles: ["customer_horeca"],
    component: () => <Horeca clientGroup={"ckbuxfzgm07xb0774cbvd90sk"}/>,
    menu: {
      title: 'Aanbod: detailhandel',
    },
  },
  // {
  //   menu: {
  //     title: 'Word deelnemer',
  //   },
  //   path: "https://www.mijnboer.be/groentegeweld/inschrijven",
  //   //roles: ["customer_horeca"],
  //   component: () => <SubscriptionWizard/>,
  // },
  {
    menu: {
      title: 'Veldwinkel',
    },
    path: "/veldwinkel/bestellen",
    //roles: ["customer_horeca"],
    component: () => <Shop/>,
  },
  {
    menu: {
      title: 'Veldwinkel info',
    },
    feest: false,
    path: "/veldwinkel",
    //roles: ["customer_horeca"],
    component: () => <ShopInfo/>,
    headerOptions: {
      height: '40',
      //height: '5vh',
      img: 'shop.jpeg',
    }
  },
  {
    hidden: true,
    // menu: {
    //   title: 'Horeca',
    // },
    path: "/oogstlijst",
    //roles: ["customer_horeca"],
    component: () => <HarvestList/>,
  },
  {
    hidden: true,
    path: "/account",
    //roles: ["customer_horeca"],
    component: () => <AccountPage/>,
  },
  {
    hidden: true,
    path: "/login",
    component: () => <div>
      <Button label={'Aanmelden'} onClick={() => loginWithRedirect({redirectUri: appConfig.redirectUrl})}/>
      <Button label={'Afmelden'} onClick={() => logout({returnTo: window.location.origin})}/>
    </div>,
  },
  // {
  //   path: "/contact",
  //   component: Contact,
  //   menu: {
  //     title: 'Contacteer ons',
  //   },
  // },
];

export const Content = styled.div`
  padding: 1vh 4vw;
  @media only screen and (min-width: 1000px) {
    max-width: 1300px;
    margin: 0 auto;
  }

`;

export const FullImage = styled.div<{ img: string, height?: string }>`
  background-image: url(${({img}) => img});
  background-size: cover;
  background-position: center;
  //background-attachment: fixed;
  height: ${({height}) => height || '50vh'};
  overflow: hidden;
  //img {
  //  object-fit: cover;
  //  width: 100%;
  //}
`;

const NavigationWrapper = styled.div`
  background-color: white;
  //background-color: white;
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  //position: fixed;
  //top: 0;
  //left: 0;
  //right: 0;
  border-bottom: 1px solid #748F56;
  padding-right: 16px;

  display: grid;
  grid-template-columns: max-content 1fr max-content;
  align-items: center;

  @media only screen and (max-width: 960px) {
    .centered-logo {
      display: grid;
    }

    .left-logo {
      display: none;
    }
  }
  @media only screen and (min-width: 961px) {
    .centered-logo {
      display: none;
    }

    .left-logo {
      display: block;
    }
  }

  .p-menubar {
    border: none;
    justify-content: space-between;
  }

  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
    padding: 0.5rem 1rem;
  }

  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
  }

  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
    font-weight: normal;
    color: #748F56;
    font-size: 1.3rem;
    font-family: Montserrat, sans-serif;
    white-space: nowrap;
    outline: none;
  }

  .p-menubar .p-menubar-root-list > .p-menuitem.active > .p-menuitem-link .p-menuitem-text {
    font-weight: bold;
    color: #EE7A31;
  }

  .p-menubar .p-menubar-root-list > .p-menuitem.highlight > .p-menuitem-link {
    background-color: #EE7A31;

    .p-menuitem-text {
      font-weight: bold;
      color: white;
    }
  }

  .p-menubar .p-menubar-root-list > .p-menuitem.highlight:hover > .p-menuitem-link {
    border: 1px solid var(--orange);
    background-color: white;

    .p-menuitem-text {
      font-weight: bold;
      color: var(--orange);
    }
  }

`;

const Logo = styled.img`
  width: 200px;
  @media only screen and (max-width: 960px) {
    width: 125px;
  }
`;

const ContentWrapper = styled.div`
  top: 96px;
  position: relative;
  @media only screen and (max-width: 960px) {
    top: 60px;
  }
`;

export const Parallax = styled.img<{ height?: string, minHeight?: string, img: string, mobileImg?: string }>`
  /* The image used */
  background-image: url(${({img}) => img});

  height: ${({height}) => height ? height : '0'};

  background-size: cover;
  width: 100%;

  @media only screen and (max-device-width: 1366px) {
    background-attachment: scroll;
  }
  // @media only screen and (max-width: 600px) {
  //   //background-size: contain;
  //   width: 100vw;
  //   overflow: hidden;
    //   background-image: url(${({img, mobileImg}) => mobileImg ? mobileImg : img});
  // }

  /* Set a specific height */
  display: block;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
`;

const WebsitePage = styled(Page)`
  font-family: Montserrat, sans-serif;
  //background-color: var(--surface-b);
  //height: 100vh;
  //overflow: scroll;

  p {
    font-size: 16px;
  }

  p a {
    font-weight: bold;
    color: var(--orange);
    text-decoration: underline;
  }
`;

const SiteHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr max-content;
`;

const App: FunctionComponent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {user: authUser} = useAuth0();

  // const pathname = location.pathname.replace(/\/+$/, "");
  const pathname = location.pathname;
  const [checkUser] = useLazyQuery<CheckUser>(CheckUserQuery);

  // let routes = ROUTES(loginWithRedirect, logout);
  let routes = ROUTES();
  const activeIdx = routes.findIndex(route => {
    return route.path === pathname;
  });
  let activeRoute = routes[activeIdx];
  let headerImg = get(activeRoute, 'headerOptions.img');
  let feest = get(activeRoute, 'feest');
  let headerMobileImg = get(activeRoute, 'headerOptions.mobileImg');
  // const {shopDetails: {cart}} = useShop();
  // let nrOfItemsInCart = values(cart.items).length;
  //
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, [pathname]);

  // useEffect(() => {
  //   // if not a hash link, scroll to top
  //   if (hash === '') {
  //     window.scrollTo(0, 0);
  //   }
  //   // else scroll to id
  //   else {
  //     setTimeout(() => {
  //       const id = hash.replace('#', '');
  //       const element = document.getElementById(id);
  //       if (element) {
  //         element.scrollIntoView();
  //       }
  //     }, 0);
  //   }
  // }, [pathname, hash, key]); // do this on route change


  useEffect(() => {
    if (authUser) {
      checkUser({variables: {email: authUser.email}});
    }

  }, [authUser, checkUser]);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: pathname + location.search,
      title: activeRoute.menu?.title
    });
  }, [pathname]);

  // useEffect(() => {
  //   if (data && !data.user) {
  //     push('/account');
  //   }
  // }, [data]);


  // if (isLoading) {
  //   return <div/>;
  // }

  // const op = useRef<any>();

  return (<>
      {/*<RemoveTrailingSlash/>*/}
      <WebsitePage id={"page"}>
        {/*<OverlayPanel ref={op}>*/}
        {/*  <ShoppingCartContainer/>*/}
        {/*</OverlayPanel>*/}
        <NavigationWrapper className="bg-white top-0 left-0 right-0 fixed">
          <div className="left-logo"><Logo src="logo.png" alt=""/></div>
          <div>
            <Menubar
              // end={location.pathname.startsWith('/veldwinkelxx')
              //   ? <i
              //     onClick={(e) => op.current?.toggle(e)}
              //     id={'cartButton'} className={'pi icon-harvest-box p-overlay-badge'}
              //     style={{fontSize: '2rem', cursor: 'pointer', marginRight: '10px'}}>
              //     {!!nrOfItemsInCart && <Badge value={nrOfItemsInCart} style={{backgroundColor: 'var(--orange)'}}/>}
              //   </i>
              //   : <div/>}
              style={{backgroundColor: 'transparent'}}
              pt={{
                menu: {
                  className: 'w-screen md:w-auto'
                }

              }}
              model={[
                {
                  label: 'Ons verhaal',
                  link: '/',
                },
                // {
                //   label: 'Feest',
                //   link: '/feest',
                // },
                {
                  label: 'CSA',
                  link: '/csa',
                  command: () => {
                    navigate('/csa');
                  }
                },
                {
                  label: 'Zelfoogst',
                  link: '/zelfoogst',
                },
                {
                  label: 'Pakketten',
                  link: '/pakketten',
                },
                {
                  label: 'Word deelnemer',
                  url: 'https://www.mijnboer.be/groentegeweld/inschrijven',
                  target: '_blank',
                  className: "highlight",
                },
                {
                  label: 'Online veldwinkel',
                  link: '/veldwinkel',
                  icon: 'pi pi-shopping-cart',
                },
              ].map(item => ({
                ...item,
                className: item.className + ' ' + classNames({'active': pathname === item.link}),
                command: item.link ? () => {
                  navigate(item?.link);
                } : undefined,
              }))}
            />
          </div>
          <div className="centered-logo justify-content-center"><Logo src="logo.png" alt=""/></div>
          <UserMenu switchSites redirectUrl={appConfig.redirectUrl} items={[]}/>
        </NavigationWrapper>
        <ContentWrapper>
          <div className="relative">
            {feest &&
              <a href="/feest" className="relative top-0 w-full bg-white flex justify-content-center">
                <img className='w-full' style={{maxWidth: '1200px'}} src={'lekkerstefeest-banner.jpg'}/>
                <div className="absolute bottom-0">
                  <div className="flex mb-2 md:mb-4">
                    <a className="text-lg sm:text-xl lg:text-2xl font-bold color-orange" href="/feest">Meer info &
                      inschrijvingen</a>
                  </div>
                </div>
              </a>
            }
            {headerImg &&
              <FullImage
                img={headerImg}
                //mobileImg={headerMobileImg}
                height={get(activeRoute, 'headerOptions.height', false) + 'vh'}
              />
            }
            {/*<div className="absolute bottom-0 w-full bg-white">*/}
            {/*  <div className="m-2 grid align-items-center justify-content-between">*/}
            {/*    <div>Bestel online via onze online veldwinkel</div>*/}
            {/*    <Button outlined label="Bestel online">*/}

            {/*    </Button>*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
          {get(activeRoute, 'showOpendeur', false) &&
            <Link to={'/veldwinkel'}>
              <img src={'contact-post.jpg'} alt="Infodag zondag 7 mei" style={{
                maxWidth: '90vw',
                maxHeight: '40vh',
                position: 'absolute',
                left: '50%',
                top: '10vh',
                border: '3px solid var(--orange)',
                transform: 'translate(-50%, 0)',
                boxShadow: '5px 5px 15px 5px #000'
              }}/>
            </Link>}
          <Routes>
            {routes.map(({component: Component, path}) => {
                // let userElement;
                // if (user) {
                //   userElement = user['http://something.com/roles'];
                // }

                // if (!isEmpty(roles)) {
                // return <SecretRoute userRoles={userElement} role={'customer_horeca'}
                //                     isAuthenticated={isAuthenticated} exact key={path} path={path} component={component}/>;
                // }
                return <Route key={path} path={path} element={<Component/>}/>;
              }
            )}
          </Routes>
        </ContentWrapper>
      </WebsitePage>
    </>
  );
};

const RoutedApp = () => <App/>;

export default RoutedApp;
