import React from 'react';
import BasketPageSection from "./BasketPageSection";

interface MenuSectionProps {
}

const MenuSection = (props: MenuSectionProps) => {
  return (
    <BasketPageSection image={'webapp-flex2.jpg'} gradientPosition={'left'} title='Kies zelf' subTitle='het menu'>
      <div className='md:flex gap-4 align-items-center'>
        <div>
          <p className="text-lg color-orange">Kies je eigen menu of laat je verrassen door de selectie van de
            boer. </p>
          <p>Elke week stellen wij een pakket samen met de pure smaken die het veld te bieden heeft. <span className="font-bold color-green">Laat je verrassen </span>
            voor de boeren en ontdek nieuwe groenten, heerlijke smaken en de lekkerste gerechten.</p>
          <p><span className="font-bold color-green">Bepaal jij liever zelf</span> het menu, personaliseer dan je pakket.</p>
        </div>


        <div className="md:col-6 mt-4 border-1 border-orange border-round-md overflow-hidden border-left-3 p-0">
          <div className="p-3">
            <p className="text-lg font-medium color-orange mb-0">Personaliseer je eigen pakket</p>
            <p className="text-gray-500 mb-0"><span
              className="font-medium color-green">Pas je pakket eenvoudig</span> aan
              via onze gebruiksvriendelijke webapp. Voeg extra groenten toe, vergroot je porties of verwijder een
              groente uit je pakket. Elke week kies je uit een gevarieerd aanbod van seizoensgebonden groenten.</p>
          </div>

          <div className="flex justify-content-end p-2">
            <a className="px-3 py-1 bg-orange text-white overflow-hidden md:text-lg uppercase border-round"
               href="https://www.mijnboer.be/groentegeweld/inschrijven/pakketten">Schrijf me in </a>
          </div>
        </div>

      </div>
    </BasketPageSection>
  );
};

export default MenuSection;
