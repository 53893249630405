import React, { ReactNode } from 'react';
import { compact } from "lodash";
import { FormulaPeriod } from "../../../shared/types/types";
import Formula from "./Formula";
import { FormulaComparator } from "../BasketsPage2";
import styled from "styled-components";
import { useQuery } from "@apollo/react-hooks";
import { GetProductsForSubscription } from "../../../__generated__/types";
import { GetProductsForSubscriptionQuery } from "../../../shared/queries/product.gql";

interface FormulaOptionProps {
  periods: FormulaPeriod[];
  children: ReactNode;
  trial: FormulaPeriod | undefined;
  type: string;
}

const BasketPageCard = styled.div`
  //border: 1px solid var(--orange-40);
  position: relative;
  font-family: Montserrat, sans-serif;
  //border: 1px solid #dee2e6;
  display: grid;
  //grid-template-columns: max-content 1fr;
  //box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%);
  border-radius: 6px;

  .p-card-image {
    background-color: red;
    width: 200px;
    height: 200px;
  }

  .p-card-title {
    color: var(--green-40);
    font-size: 1.25rem;
    font-weight: normal;
  }

  &.has-value {
    .p-card-title {
      color: var(--green);
    }
  }


  .p-card-footer {
    color: var(--green-80);
    text-align: right;
    padding: 0.25rem 0 0 0;

    .p-button {
      font-family: Montserrat, sans-serif;
      font-size: 12px;
      padding: 0;
      font-weight: normal;
    }
  }

  .remark {
    font-size: 80%;
    font-style: italic;
  }

  h1.main {
    font-size: 2.5em;
    font-weight: bold;
  }
`;

const FormulaOption = (props: FormulaOptionProps) => {
  const {data} = useQuery<GetProductsForSubscription>(GetProductsForSubscriptionQuery);

  return <BasketPageCard>
    <div className="p-card-content">
      {props.children}
      <FormulaComparator items={3}>
        {props.periods
          .map((period: FormulaPeriod, idx) => {
            return <Formula
              key={period.code || period.codeMini || period.codeSmall || period.codeLarge}
              products={compact(data?.products)}
              period={period}
              idx={idx}
              type={props.type}
            />;
          })}
      </FormulaComparator>
      <p className={'remark'} style={{paddingTop: '8px'}}>
        * Niet elk pakket zal even groot zijn, de prijs is dan ook een gemiddelde.
      </p>

      {props.trial && <div>
        <p className={'small'}>Eerst eens proberen? Dat kan, neem <strong><a
          href={`https://www.mijnboer.be/groentegeweld/inschrijven/pakketten`}>hier</a></strong> een
          proefperiode van 8 weken.</p>
      </div>}
    </div>
  </BasketPageCard>;
};

export default FormulaOption;
